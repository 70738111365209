

md-toast[md-theme=warn] .md-toast-content {
  background-color: #F44336; }

[ui-view].ng-enter,
[ui-view].ng-leave {
  transition: opacity ease-in-out 200ms; }

[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0; }

[ui-view].ng-enter.ng-enter-active {
  opacity: 1; }

.Page.ng-leave.ng-leave-active {
  display: none; }

.u-center {
  text-align: center; }

.Footer {
  text-align: center;
  background-color: #F6F9FA;
  border-top: 1px solid #E9F0F3;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #888888;
  font-weight: 300; }

.Footer-text {
  padding-top: 10px;
  opacity: 0.8;
  color: #888888; }

.Footer-link {
  text-decoration: none;
  color: #888888 !important;
  font-weight: 400; }

.Footer-link:hover {
  text-decoration: underline; }

.Footer-logo {
  width: 49px;
  height: 46px; }

.ForgotPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.forgot-pass a.forgot-password::after {
  display: none;
  content: inherit; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  font-size: 20px;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  font-size: 20px;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.Landing {
  height: 100%; }

.Landing-cover {
  background-image: url("/img/cover.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  height: 700px; }

.Landing-heading,
.Landing-subtitle {
  color: white; }

.Landing-headingLight {
  font-weight: 300; }

.Landing-heading {
  color: white;
  letter-spacing: -3px;
  margin-top: 20px;
  font-size: 60px;
  text-align: center; }

.Landing-subtitle {
  opacity: 0.7;
  text-align: center; }

.Landing-getStarted {
  padding: 12px 30px;
  font-weight: bold;
  color: #535362 !important;
  border-radius: 4px;
  text-transform: box-shadow 250ms; }

.Landing-getStarted:hover {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important; }

.Landing-laravelAngular {
  position: relative; }

.Landing-ampersand {
  font-size: 50px;
  color: #00A4C6;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 300; }

.Landing-angular {
  background-color: #F6F9FA;
  border-left: 1px solid #E9F0F3; }

.Landing-laravel,
.Landing-angular {
  text-align: center;
  color: #21455B;
  border-bottom: 1px solid #E9F0F3; }

.Landing-laravelAngular-title {
  padding-top: 30px;
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #21455B; }

.Landing-laravelAngular-subtitle {
  color: #535362;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 300; }

.DemoCode {
  font-family: "Lucida Console", Monaco, monospace;
  overflow: auto !important;
  padding: 10px;
  line-height: 1.428571429;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 80%;
  margin: 30px auto 100px;
  text-align: left;
  padding-left: 20px;
  font-size: 14px; }

.DemoCode-highlight {
  color: #DD4A68; }

.DemoCode-comment {
  color: #888888; }

.DemoCode-operator {
  color: #888888; }

.DemoCode-string {
  color: #690; }

.DemoCode-secondary {
  color: #07A; }

.Landing-features {
  text-align: center;
  margin: 60px 10px; }

.Landing-featuresMainTitle {
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 15px;
  color: #21455B; }

.Landing-featuresMainDescription {
  width: 70%;
  margin: 0 auto 30px;
  color: #535362;
  font-weight: 300; }

.Landing-featuresEntry {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  margin: 40px auto 20px; }

.Landing-featuresEntry-icon {
  height: 100%; }

.Landing-featuresTitle {
  color: #21455B;
  letter-spacing: -1px;
  margin-top: 40px; }

.Landing-featuresDescription {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 300;
  color: #535362; }

.Landing-featuresEntry--restful {
  background-color: #00A4C6; }

.Landing-featuresEntry--jwt {
  background-color: #EF704D; }

.Landing-featuresEntry--generators {
  background-color: #25C0CC; }

.Landing-featuresEntry--restful md-icon {
  width: 100px; }

.Landing-featuresEntry--jwt md-icon {
  width: 80px; }

.Landing-featuresEntry--generators md-icon {
  width: 80px; }

/*
This is a terrible temporary hack,
to fix layout issues with flex on iOS (chrome & safari)
Make sure to remove this when you modify this demo
*/
@media only screen and (max-width: 950px) {
  .iOS-hack .flex-100,
  .iOS-hack .flex-50,
  .iOS-hack .flex-33 {
    -ms-flex: none !important;
    flex: none !important; } }

/* CSS Document */
body {
  background: #fff !important;
  color: #272626;
  font-size: 14px;
  font-family: "Raleway", sans-serif !important; }

img a, img {
  border: 0px;
  outline: none; }

* {
  margin: 0;
  padding: 0px; }

a {
  text-decoration: none;
  outline: none; }

a:hover {
  text-decoration: none; }

ul, ol {
  list-style: none;
  margin: 0px; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0px;
  padding: 0px; }

a:hover, a:focus {
  outline: none;
  outline-offset: inherit;
  text-decoration: none; }

img {
  max-width: 100%; }

/*****************************************/
.register-new-user {
  position: relative;
  /* height: 100%; */
  height: calc(100vh);
  overflow: hidden;
  width: 100%; }

.right-block::after {
  content: "";
  height: 1194px;
  width: 100%;
  background: #00b95c;
  position: absolute;
  transform: rotate(19deg);
  right: -60px;
  top: -150px;
  border: 6px solid #ffc518; }

.left-block input[type="text"], .left-block input[type="email"], .left-block input[type="password"] {
  height: 52px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 283px;
  margin-right: 8px; }

.left-block input[type="submit"] {
  background: #ffac2c;
  height: 52px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 700;
  padding: 0 26px; }

.top-info {
  margin-top: 36%;
  margin-bottom: 50px; }

.top-info p {
  font-size: 20px;
  font-weight: 300; }

.top-info h1 {
  font-size: 37px;
  font-weight: 300;
  margin-bottom: 15px;
  color: #000; }

.main-logo {
  margin: 0px 0 0 0; }

header.login-main-header {
  width: 100%;
  display: inline-block;
  box-shadow: 0 0 6px 0 #ccc;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 12px 0 12px 0; }

.header-nav ul li {
  display: inline-block;
  padding: 0 0 0 51px;
  position: relative; }

.header-nav ul li a {
  color: #898989;
  font-size: 15px;
  font-weight: 700; }

.header-nav {
  margin-top: 14px; }

.header-nav ul li a.active {
  color: #000; }

.header-nav ul li a:hover {
  color: #000; }

.right-inner {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 25%; }

.header-nav ul li::after {
  content: "/";
  position: absolute;
  right: -32px;
  top: -2px;
  font-size: 19px;
  /* font-weight: 700; */
  color: #898989; }

.main-content {
  padding-top: 180px; }

.user-wrap input[type="text"], .user-wrap input[type="email"], .user-wrap input[type="password"] {
  height: 52px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 39px;
  width: 100%;
  max-width: 100%;
  margin-right: 0; }

.user-wrap input[type="text"]:focus, .user-wrap input[type="email"]:focus, .user-wrap input[type="password"]:focus {
  outline: none; }

.user-wrap input[type="submit"] {
  background: #00d068;
  height: 52px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 700;
  padding: 0 0px;
  width: 100%; }

.user-wrap h1 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 12px; }

.user-wrap p {
  font-size: 19px;
  font-weight: 300; }

.user-wrap {
  max-width: 345px;
  margin: 0 auto;
  text-align: center; }

a.forgot-password {
  color: #174acc;
  position: relative; }

a.create-account {
  color: #174acc; }

a.create-account:hover {
  color: #ffac2c; }

a.forgot-password:hover {
  color: #ffac2c; }

a.forgot-password::after {
  content: "";
  position: absolute;
  right: -55px;
  font-size: 20px;
  top: -2px;
  color: #ccc;
  height: 25px;
  width: 1px;
  background: #ccc; }

.form-group .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 52px !important;
  height: 52px !important;
  line-height: 52px !important;
  text-align: center;
  pointer-events: none;
  font-size: 22px;
  color: #6bd0fc; }

.form-group {
  position: relative; }

.login a {
  display: inline-block;
  background: #00A9FF;
  color: #fff;
  padding: 12px 18px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase; }

.login a i {
  margin: 0 0 0 6px;
  font-size: 14px; }

.header-nav ul li:last-child::after {
  content: inherit;
  display: none; }

.login a:hover {
  background: #00d068;
  color: #fff;
  text-decoration: none; }

.contact-info-top ul li {
  display: inline-block;
  padding: 0 0 0 30px; }

.contact-info-top {
  text-align: right;
  margin-top: 11px; }

.contact-info-top i {
  font-size: 18px;
  background: #00d068;
  color: #fff;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 50px; }

.contact-info-top ul li a {
  color: #333;
  font-weight: 400;
  font-size: 18px;
  font-family: oswald;
  position: relative;
  top: 3px; }

.login {
  margin-top: 2px; }

/* .main-content {

    background: url(../images/graphic1.svg);
}
 */
.login-user {
  padding-top: 100px; }

.callout p {
  font-size: 15px; }

.contact-info-top h3 {
  margin: 0px; }

.forgot-pass {
  margin-top: 180px; }

@media (min-width: 320px) and (max-width: 767px) {
  .right-inner {
    margin-top: 0; }
  .register-new-user {
    height: auto;
    overflow: visible; }
  .right-block::after {
    content: inherit;
    display: none; }
  .left-block input[type="button"] {
    padding: 0;
    width: 100%; }
  .left-block input[type="text"] {
    height: 52px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 100%;
    margin-right: 8px;
    margin-bottom: 15px; }
  .right-block {
    margin-top: 30px;
    margin-bottom: 30px;
    display: inline-block; }
  a.forgot-password::after {
    right: -30px; }
  .login a {
    padding: 8px 10px;
    font-size: 10px; }
  .login a i {
    font-size: 10px; }
  .top-info h1 {
    font-size: 30px;
    line-height: normal; }
  .top-info {
    margin-top: 100px; }
  .main-content {
    padding-top: 100px; }
  .user-wrap p {
    font-size: 14px; }
  .left-block input[type="submit"] {
    width: 100%; }
  .footer-main {
    margin-top: 0 !important; }
  .left-block .register-field {
    max-width: 100%;
    margin-right: 0; }
  .left-block .form-control {
    margin-right: 0 !important;
    max-width: 100% !important; }
  .login-user {
    margin-bottom: 30px;
    padding-top: 70px; }
  .login {
    padding-left: 0 !important; }
  .forgot-pass {
    margin-top: 155px;
    margin-bottom: 60px; }
  .forgot-pass h2 {
    font-size: 20px; } }

.dataTables_filter {
  float: right; }

.dataTables_filter input {
  margin: 0px 5px 0px 5px; }

.dataTables_paginate {
  float: right; }

.dataTables_paginate .pagination {
  margin: 0px; }

@media print {
  body {
    font-size: 10px; } }

.md-select-menu-container {
  z-index: 2000 !important; }

.row {
  margin-top: 10px; }

.margin-bottom {
  margin-bottom: 20px; }

.dt-button-collection {
  width: 500px !important; }

.buttons-columnVisibility {
  min-width: 100px;
  float: left !important;
  margin-right: 5px !important; }

/*

#loading-bar {
    z-index: 9999999;
}

#loading-bar-spinner {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-size: 100px auto;
    z-index: 10000;
    background-color: rgba(255, 255, 255, .7);
     
}


#loading-bar-spinner .spinner-icon {
    display: block
}
*/
.dropdown.open .dropdown-menu.user-toggle {
  position: absolute; }

.content-header {
  min-height: 40px; }

.validationError {
  color: #dd4b39; }

.notification_message {
  font-size: 13px;
  padding-top: 5px;
  color: #dd4b39; }

.tree-node-ico.tree-node-expanded {
  background-image: url(/img/arrow-down.png) !important; }

.tree-node-ico.tree-node-collapsed {
  background-image: url(/img/arrow-right.png) !important; }

span > .tree-node-ico.tree-node-image {
  background-image: url(/img/team.png) !important; }

span > .tree-node-ico.tree-node-leaf {
  background-image: url(/img/team_single.png) !important; }


.ResetPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.Landing {
  height: 100%; }

.Landing-cover {
  background-image: url("/img/cover.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  height: 700px; }

.Landing-heading,
.Landing-subtitle {
  color: white; }

.Landing-headingLight {
  font-weight: 300; }

.Landing-heading {
  color: white;
  letter-spacing: -3px;
  margin-top: 20px;
  font-size: 60px;
  text-align: center; }

.Landing-subtitle {
  opacity: 0.7;
  text-align: center; }

.Landing-getStarted {
  padding: 12px 30px;
  font-weight: bold;
  color: #535362 !important;
  border-radius: 4px;
  text-transform: box-shadow 250ms; }

.Landing-getStarted:hover {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important; }

.Landing-laravelAngular {
  position: relative; }

.Landing-ampersand {
  font-size: 50px;
  color: #00A4C6;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 300; }

.Landing-angular {
  background-color: #F6F9FA;
  border-left: 1px solid #E9F0F3; }

.Landing-laravel,
.Landing-angular {
  text-align: center;
  color: #21455B;
  border-bottom: 1px solid #E9F0F3; }

.Landing-laravelAngular-title {
  padding-top: 30px;
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #21455B; }

.Landing-laravelAngular-subtitle {
  color: #535362;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 300; }

.DemoCode {
  font-family: "Lucida Console", Monaco, monospace;
  overflow: auto !important;
  padding: 10px;
  line-height: 1.428571429;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 80%;
  margin: 30px auto 100px;
  text-align: left;
  padding-left: 20px;
  font-size: 14px; }

.DemoCode-highlight {
  color: #DD4A68; }

.DemoCode-comment {
  color: #888888; }

.DemoCode-operator {
  color: #888888; }

.DemoCode-string {
  color: #690; }

.DemoCode-secondary {
  color: #07A; }

.Landing-features {
  text-align: center;
  margin: 60px 10px; }

.Landing-featuresMainTitle {
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 15px;
  color: #21455B; }

.Landing-featuresMainDescription {
  width: 70%;
  margin: 0 auto 30px;
  color: #535362;
  font-weight: 300; }

.Landing-featuresEntry {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  margin: 40px auto 20px; }

.Landing-featuresEntry-icon {
  height: 100%; }

.Landing-featuresTitle {
  color: #21455B;
  letter-spacing: -1px;
  margin-top: 40px; }

.Landing-featuresDescription {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 300;
  color: #535362; }

.Landing-featuresEntry--restful {
  background-color: #00A4C6; }

.Landing-featuresEntry--jwt {
  background-color: #EF704D; }

.Landing-featuresEntry--generators {
  background-color: #25C0CC; }

.Landing-featuresEntry--restful md-icon {
  width: 100px; }

.Landing-featuresEntry--jwt md-icon {
  width: 80px; }

.Landing-featuresEntry--generators md-icon {
  width: 80px; }

/*
This is a terrible temporary hack,
to fix layout issues with flex on iOS (chrome & safari)
Make sure to remove this when you modify this demo
*/
@media only screen and (max-width: 950px) {
  .iOS-hack .flex-100,
  .iOS-hack .flex-50,
  .iOS-hack .flex-33 {
    -ms-flex: none !important;
    flex: none !important; } }

.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.Register-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.app-download-btns li img {
  max-width: 180px; }

.app-download-btns li {
  margin-right: 0; }

.app-download-btns {
  margin-top: 30px;
  text-align: left; }

.app-download-btns ul li {
  display: inline-block;
  margin-bottom: 13px; }

.top-info.top-register {
  margin-top: 29%; }

@media (min-width: 1650px) and (max-width: 2200px) {
  .register-new-user .right-block::after {
    content: "";
    height: 1700px !important;
    width: 100%;
    background: #00b95c;
    position: absolute;
    transform: rotate(19deg);
    right: -100px !important;
    top: -150px;
    border: 6px solid #ffc518; } }

.dataTables_wrapper table.table thead th {
  white-space: nowrap;
  background: #333;
  color: #fff;
  border-color: #727272; }

.dataTables_wrapper table.table td {
  white-space: nowrap; }

.pagination > li > a, .pagination > li > span {
  padding: 9px 16px;
  color: #000; }

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #fdab3d;
  border-color: #fdab3d; }

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  border-color: #ccc; }

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  background-color: #f3f1f4;
  border-color: f #ccc; }

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  background-color: #f3f1f4;
  border-color: #ccc; }

.box-header.with-border .box-tools a {
  /*background: #02cc6f;
	border-color: #02cc6f;*/
  padding: 7px 17px;
  display: inline-block;
  margin-top: -2px;
  width: auto; }

.user-footer a {
  border-radius: 10px !important;
  background: #00d068 !important;
  color: #fff !important;
  padding: 9px 22px;
  font-weight: 700;
  box-shadow: inherit !important;
  border: none; }

.success-block {
  font-size: 14px !important;
  color: #00a65a !important; }

.tab-pane {
  padding: 20px; }




.headline-title {
  background-color: #3c8dbc;
  padding: 1px 0 2px 12px; }

.info-box.performance-tabs {
  min-height: 43px; }





.pad-bottom {
  padding-bottom: 20px; }



.fc-event {
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px; }



.profile-info-left {
  width: 100%;
  display: inline-block;
  background: #fff;
  padding: 0;
  /* border-top: 3px solid #28a745; */ }

.profile-info-right {
  display: inline-block;
  width: 100%; }

.profile-info-left .profile-thumb {
  float: none;
  margin: 0 auto; }

.company-name-title {
  font-size: 27px;
  font-weight: 700;
  margin-top: 0;
  text-align: center;
  margin-bottom: 30px; }

.data-index {
  background: #fff;
  padding: 15px;
  width: 100%;
  display: inline-block; }

.profile-info-right .data-discription-tab {
  padding: 0; }

.tab-content.index-content {
  padding: 15px;
  background: #fff;
  margin-top: 20px; }

.panel.panel-border {
  border: 1px solid #ccc; }

.panel.panel-border textarea {
  border: none;
  box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
  resize: none;
  color: #222; }

.panel-border .panel-footer {
  border-top: none; }

button.btn-post {
  color: #fff;
  background-color: #28a745; }

ul.activity-list .activity-desk {
  margin-left: 70px; }

ul.activity-list .avatar img {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%; }

ul.activity-list li {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eff0f4; }

ul.activity-list .activity-desk h5 {
  color: #2a323f; }

.text-muted {
  color: #919aa3 !important;
  margin-bottom: 10px; }

ul.activity-list .activity-desk h5 a {
  font-weight: bold; }

.profile-info-left ul {
  padding: 0;
  list-style: none; }

.company-image img {
  width: 100%; }

.company-info-block {
  padding: 15px; }

ul.info-list li {
  border-top: 1px solid #ccc;
  padding: 12px 6px; }

ul.activity-list {
  padding-left: 0px; }




.status-color-New {
  background-color: #0000FF !important; }

.status-color-Hot {
  background-color: #dd4b39 !important; }

.status-color-Cold {
  background-color: #00c0ef !important; }

.status-color-Warm {
  background-color: #f39c12 !important; }

.status-color-Matured {
  background-color: #D81B60 !important; }

.status-color-Not Interested {
  background-color: #001F3F !important; }

.head-title {
  color: #333;
  font-size: 14px;
  font-weight: 600; }

.status-inactive {
  color: #f6000c; }

.status-ideal {
  color: #ff7f00; }






.ForgotPassword-input {
  margin-bottom: 0; }





invoice-table th {
  border-bottom: 1px solid #f1f1f1 !important;
  border-top: none !important; }

table.table.invoice-table th, table.table.invoice-table td {
  border-bottom: 1px solid #f1f1f1 !important;
  border-top: none !important; }

.box-tools.pull-right a {
  display: inline;
  padding: 4px 8px; }

.col-lead {
  padding-left: 0px !important;
  margin-bottom: 15px; }

.lead-btnn {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.lead-btnn button.btn {
  display: inline-block; }

.status-change {
  background: #5cb85c;
  color: #fff; }

.checkbox .col-sm-4 {
  margin-bottom: 16px; }

.table-responsive-1 {
  overflow-x: auto;
  float: left;
  width: 100%; }

span.span-block {
  display: block !important;
  text-align: center;
  white-space: nowrap; }

.dataTable .tooltip-inner {
  text-align: left;
  /* white-space: pre-wrap;*/ }




.onoffswitch {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.onoffswitch-checkbox {
  display: none; }

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #999999;
  border-radius: 20px; }

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s; }

.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box; }

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #34A7C1;
  color: #FFFFFF; }

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #EEEEEE;
  color: #999999;
  text-align: right; }

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 6px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px;
  border: 2px solid #999999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s; }

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0; }

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px; }


.profile-thumb {
  width: 118px;
  height: 118px;
  text-align: center;
  border: 2px solid;
  line-height: 118px;
  border-radius: 50%;
  font-size: 37px;
  float: left;
  margin-right: 12px; }

.profile-user-name {
  font-size: 20px;
  font-weight: 700;
  margin-top: 19px; }

.profile-formating-btns {
  text-align: right; }

.profile-formating-btns ul li {
  list-style: none;
  display: inline-block;
  padding: 0 0 0 7px; }

.profile-formating-btns ul li a {
  display: inline-block;
  background: #ccc;
  color: #000;
  border-radius: 50px;
  padding: 7px 19px;
  text-decoration: none;
  font-size: 13px;
  line-height: normal; }

.profile-formating-btns ul li a:hover {
  color: #fff;
  background-color: #28a745; }

.profile-formating-btns ul {
  list-style: none;
  display: inline-block;
  margin: 0px;
  padding: 0px; }

.profile-info-bar {
  width: 100%;
  margin-top: 20px;
  display: inline-block;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px; }

.data-discription-tab {
  clear: both;
  padding: 20px; }

.data-discription-tab .nav.nav-pills li a {
  border-radius: 50px;
  padding: 7px 15px;
  border: none;
  text-decoration: none;
  font-weight: normal; }

.data-discription-tab .nav.nav-pills li.active a {
  color: #fff;
  background-color: #28a745; }

.data-discription-tab .nav.nav-pills li a:hover {
  color: #fff;
  background-color: #28a745; }

.data-discription-tab .nav.nav-pills li {
  padding: 0 6px 0 0; }

.data-discription-tab {
  clear: both;
  padding: 20px;
  width: 100%;
  display: inline-block; }

.data-discription-tab h4 {
  background: #222c3c;
  padding: 10px 15px;
  color: #fff; }

.teblee {
  font-weight: 700;
  color: #222c3c; }

.data-discription-tab .lead-details table tr td {
  padding: 11px 8px; }

.panel.panel-border {
  border: 1px solid #ccc; }

.panel.panel-border textarea {
  border: none;
  box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
  resize: none;
  color: #222; }

.panel-border .panel-footer {
  border-top: none; }

.panel-footer button.btn-post {
  background: #28a745;
  color: #fff;
  border-radius: 50px; }

ul.activity-list .activity-desk {
  margin-left: 70px; }

ul.activity-list .avatar img {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%; }

ul.activity-list li {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eff0f4; }

ul.activity-list .activity-desk h5 {
  color: #2a323f; }

.text-muted {
  color: #919aa3 !important;
  margin-bottom: 10px; }

ul.activity-list .activity-desk h5 a {
  font-weight: bold; }

.profile-info-left ul {
  padding: 0;
  list-style: none; }

.company-image img {
  width: 100%; }

.company-info-block {
  padding: 15px; }

ul.info-list li {
  border-top: 1px solid #ccc;
  padding: 12px 6px; }

ul.activity-list {
  padding-left: 0px; }

#comments .panel-border {
  margin-top: 15px; }

.data-index {
  background: #fff;
  padding: 15px;
  width: 100%;
  display: inline-block; }

.tab-content.index-content {
  padding: 15px;
  background: #fff;
  margin-top: 20px;
  width: 100%;
  display: inline-block; }

.info-bar-inner {
  background: #fff;
  display: inline-block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px; }


@charset "UTF-8";
/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
.simpleDemo ul[dnd-list] {
  min-height: 42px;
  padding-left: 0px; }

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
.simpleDemo ul[dnd-list] .dndDraggingSource {
  display: none; }

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
.simpleDemo ul[dnd-list] .dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 42px; }

.simpleDemo ul[dnd-list] li {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  cursor: move; }

/**
 * Show selected elements in green
 */
.simpleDemo ul[dnd-list] li.selected {
  background-color: #dff0d8;
  color: #3c763d; }

.simpleDemo .panel-body {
  height: 325px;
  overflow-y: scroll; }

.panel-info > .panel-heading {
  color: #fff !important;
  background-color: #00c0ef !important;
  border-color: #00c0ef !important; }

.modal-fullscreen {
  padding: 0 !important;
  z-index: 1030 !important; }
  .modal-fullscreen .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0; }
  .modal-fullscreen .modal-content {
    height: auto;
    min-height: 100%;
    border: 0 none;
    border-radius: 0; }

table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
  background-color: #F9F1F1; }

table.dataTable tbody .selected {
  background-color: #F9F18A !important; }

.leadstatus_count li a {
  color: #fff; }

.leadstatus_count li a:hover {
  text-decoration: none; }

.leadstatus_count {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%; }

.leadstatus_count ul li.col-xs-1 {
  width: 16.1%;
  margin-right: 5px;
  margin-bottom: 5px; }

.leadstatus_count ul li.col-xs-1 h3 {
  margin-top: 2px;
  margin-bottom: 2px; }

.leadstatus_count ul li.col-xs-1 p {
  margin-bottom: 5px; }

@media (min-width: 320px) and (max-width: 767px) {
  .leadstatus_count {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    clear: both;
    margin-top: 34px; }
  .leadstatus_count ul li.col-xs-1 {
    width: 48%;
    margin: 2px 2px; }
  .leadstatus_count ul {
    display: inherit; }
  html body .box-body .col-sm-12.lead-list-all-user button:last-child {
    float: right !important;
    margin-right: 0 !important; }
  .lead-list-dropdown {
    margin-bottom: 10px; } }

.info-box {
  min-height: 56px; }

.info-box-content {
  margin-left: 26px; }

/** nav- division **/
.col-sm-12.nav-division {
  padding-left: 0;
  padding-right: 0; }

.nav-division h3.info {
  font-size: 14px;
  color: #b8c7ce;
  margin: 0;
  padding: 12px 5px 12px 15px; }

.nav-division h3.info::before {
  content: "⊞";
  font-size: 18px;
  margin-right: 12px; }

.nav-division md-select.md-default-theme:not([disabled]):focus .md-select-value, .nav-division md-select:not([disabled]):focus .md-select-value {
  border-bottom-color: rgba(255, 255, 255, 0.87) !important;
  color: rgba(255, 255, 255, 0.87) !important; }

.nav-division label.md-required {
  color: #fff !important;
  font-size: 18px;
  font-weight: normal; }

.nav-division .col-sm-12.md-input-has-value {
  padding-left: 0;
  padding-right: 0;
  margin-top: 30px; }

.nav-division md-select.md-default-theme .nav-division .md-select-value, md-select .md-select-value {
  border-bottom-color: rgba(255, 255, 255, 0.12); }

dashboard .small-box > .small-box-footer {
  position: relative;
  text-align: center;
  padding: 3px 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 10;
  background: transparent;
  text-decoration: none;
  font-size: 0px;
  height: 100%;
  position: absolute;
  z-index: 11111111111111;
  width: 100%;
  top: 0; }

dashboard .small-box .icon {
  font-size: 42px !important; }

dashboard .small-box h3 {
  margin: 0 0 0px 0; }

dashboard .small-box .inner p.ng-binding {
  min-height: 17px;
  height: 0; }

dashboard .small-box p {
  font-size: 15px;
  margin-bottom: 0; }

dashboard #daterange-btn, dashboard #daterange-leads_stats {
  margin-bottom: 10px; }

leads_new div.dataTables_wrapper div.dataTables_filter input {
  width: 100%;
  max-width: 400px;
  font-size: 14px; }

leads_new div.dataTables_wrapper div.dataTables_filter label {
  width: 100%; }

leads_new div.dataTables_wrapper div.dataTables_filter {
  width: 100%;
  max-width: 452px; }

@media (min-width: 768px) and (max-width: 1023px) {
  .leadstatus_count ul li.col-xs-1 p {
    font-size: 10px; }
  .leadstatus_count ul li.col-xs-1 h3 {
    font-size: 20px; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .leadstatus_count ul li.col-xs-1 p {
    font-size: 12px; }
  .leadstatus_count ul li.col-xs-1 h3 {
    font-size: 20px; } }

.badge-primary {
  color: #fff !important;
  background-color: #007bff !important; }

.tooltip-inner {
  white-space: pre-wrap;
  text-align: left; }

.tooltip.show p {
  text-align: left; }





.btn-github:hover {
  color: #fff; }

.btn-facebook:hover {
  color: #fff; }

.btn-google:hover {
  color: #fff; }



.btn {
  margin-right: 8px; }

.angular-ui-tree-handle {
  background: #f8faff;
  border: 1px solid #dae2ea;
  color: #7c9eb2;
  padding: 10px 10px; }

.angular-ui-tree-handle:hover {
  color: #438eb9;
  background: #f4f6f7;
  border-color: #dce2e8; }

.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  box-sizing: border-box; }

tr.angular-ui-tree-empty {
  height: 100px; }

.group-title {
  background-color: #687074 !important;
  color: #FFF !important; }

/* --- Tree --- */
.tree-node {
  border: 1px solid #dae2ea;
  background: #f8faff;
  color: #7c9eb2; }

.nodrop {
  background-color: #f2dede; }

.tree-node-content {
  margin: 10px; }

.tree-handle {
  padding: 10px;
  background: #428bca;
  color: #FFF;
  margin-right: 10px; }

.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  box-sizing: border-box; }


/******dashboard *******/
header.crm-header ul {
  margin-bottom: 0px; }

.navbar .sidebar-toggle {
  color: #222 !important;
  padding: 23px 15px !important; }

header.crm-header {
  width: 100%;
  display: inline-block;
  box-shadow: 0 0 6px 0 #ccc;
  background: #fff;
  padding: 0px 0 0px 0;
  /*position:fixed;*/
  top: 0px;
  left: 0;
  right: 0px; }

.skin-blue .main-header .navbar {
  background-color: #fff !important; }

header.crm-header .main-logo {
  margin: 0px 0 0 0;
  border-right: 1px solid #ccc;
  min-height: 66px;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex; }

.dropdown .dropdown-menu {
  transition: all 0.3s;
  visibility: hidden;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0; }

.main-header .logo .logo-lg {
  margin-top: 8px;
  border-right: 1px solid #ccc;
  padding-right: 10px; }

.main-header .logo .logo-mini {
  margin-top: 8px;
  border-right: 1px solid #ccc;
  padding-right: 10px; }

.dropdown.open .dropdown-menu {
  max-height: 200px;
  opacity: 1;
  visibility: visible; }

.main-header .logo {
  height: 66px !important; }

.user-mini-profile a.dropdown-toggle {
  display: inline-block;
  background: #fff;
  color: #000 !important;
  padding: 13px 12px;
  border-radius: 0px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase; }

a.dropdown-toggle span.ng-binding {
  margin-top: 8px;
  /* padding-top: 11px; */
  display: inline-block; }

.user-mini-profile .open a.dropdown-toggle {
  background: #f8f8f8;
  color: #000; }

.user-mini-profile a.dropdown-toggle:hover {
  background: #f8f8f8;
  color: #000; }

.user-mini-profile a.dropdown-toggle img {
  border-radius: 50%;
  border: 2px solid #000;
  padding: 1px;
  /* background: #fff; */
  width: 42px !important;
  height: 42px !important;
  margin-right: 10px; }

.user-mini-profile li {
  list-style: none;
  display: inline-block; }

.dropdown.open .dropdown-menu.user-toggle {
  border-radius: 0px;
  /* border: none; */
  margin-top: 0;
  background: #fff;
  /* box-shadow: inherit; */
  width: 280px;
  right: 0;
  left: auto;
  max-height: 364px;
  padding: 15px;
  /* box-shadow: 0 14px 9px 0 #eeee; */ }

.skin-blue .main-header .logo {
  background-color: #fff !important; }

.user-mini-profile li .user-toggle li {
  display: block; }

.user-header {
  text-align: center; }

.user-panel {
  margin-top: 13px; }

.user-header img {
  text-align: center;
  border-radius: 50%;
  border: 4px solid #fff;
  padding: 2px; }

.user-header h2 {
  font-size: 21px;
  font-weight: 700;
  margin-top: 12px;
  margin-bottom: 5px;
  color: #000;
  border-top: 1px solid #ccc;
  padding-top: 13px; }

.user-header p {
  color: #000; }

.user-footer a {
  border-radius: 10px;
  background: #00d068 !important;
  border: none;
  color: #fff;
  padding: 9px 22px;
  font-weight: 700;
  box-shadow: inherit;
  border: none; }

.user-mini-profile li.user-footer {
  border-top: 1px solid #ccc;
  width: 100%;
  display: inline-block;
  padding-top: 20px; }

.crm-body {
  margin-top: 66px;
  width: 100%;
  display: inline-block; }

/********** notification toggle css *********/
.notification-center ul li {
  display: inline-block;
  padding: 0 0px; }

.notification-center ul li i {
  color: #6f6f6f; }

.notification-center ul li ul li {
  width: 100%; }

.notification-center a.dropdown-toggle {
  height: 66px;
  display: inline-block;
  width: 100%;
  padding: 0 15px; }

.notification-center a.dropdown-toggle:hover {
  background: #f8f8f8; }

.notification-center .dropdown.open a.dropdown-toggle {
  background: #f8f8f8; }

.circle-out {
  margin-top: 27px;
  margin-left: 12px; }

.notification-center ul.dropdown-menu {
  border-radius: 0px;
  margin-top: 0px;
  max-height: inherit !important; }

.notification-center .open ul.dropdown-menu {
  z-index: 1111; }

.notification-center ul li.dropdown::after {
  height: 20px;
  width: 1px;
  background: #ccc;
  position: absolute;
  right: -2px;
  top: 0;
  content: "";
  margin: auto;
  bottom: 0; }

.notification-center ul li.dropdown:last-child::after {
  content: inherit !important;
  display: none; }

h4.drop-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #313131;
  font-size: 15px;
  font-weight: 700;
  padding: 11px 20px 15px; }

.toggle-icon {
  float: left;
  /* line-height: 66px; */
  margin: 27px 20px 0 0; }

.mailbox {
  width: 280px;
  overflow: auto;
  padding-bottom: 0; }

.message-center a {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  display: block;
  padding: 9px 15px; }

.message-center a:hover {
  background: #f7fafc; }

.message-center .user-img {
  width: 40px;
  float: left;
  position: relative;
  margin: 0 10px 15px 0; }

.img-circle {
  border-radius: 50%; }

.message-center .user-img .profile-status {
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 30px;
  position: absolute;
  top: 1px;
  width: 10px; }

.message-center .user-img .online {
  background: #53e69d; }

.message-center .mail-contnet h5 {
  margin: 0;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #222; }

.message-center .mail-contnet .mail-desc {
  font-size: 12px;
  display: block;
  margin: 5px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #313131; }

.message-center .mail-contnet .time {
  display: block;
  font-size: 10px;
  color: #313131; }

.dropdown-tasks li a {
  padding: 15px 20px;
  border-bottom: 1px solid #eee; }

.navbar-top-links .progress {
  margin-bottom: 6px; }

.progress {
  -webkit-box-shadow: none !important;
  background-color: rgba(120, 130, 140, 0.13);
  box-shadow: none !important;
  height: 10px;
  border-radius: 0;
  margin-bottom: 18px;
  overflow: hidden;
  border-radius: 50px; }

.progress-bar-success {
  background-color: #53e69d; }

.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px; }

.dropdown-tasks {
  width: 310px;
  min-width: 0;
  padding: 0; }

.dropdown-tasks .progress {
  margin-bottom: 6px;
  width: 100%;
  margin-top: 12px;
  display: inline-block; }

.notification-center {
  float: left; }

.pulsating-circle {
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px; }

.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  bottom: 10px;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #f6000c;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }

.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f6000c;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite; }

@keyframes pulse-ring {
  0% {
    transform: scale(0.33); }
  80%,
  100% {
    opacity: 0; } }

@keyframes pulse-dot {
  0% {
    transform: scale(0.8); }
  50% {
    transform: scale(1); }
  100% {
    transform: scale(0.8); } }

.badge-success {
  color: #fff !important;
  background-color: #28a745 !important; }

.badge-danger {
  color: #fff !important;
  background-color: #dc3545 !important; }

.badge-warning {
  color: #212529 !important;
  background-color: #ffc107 !important; }

.notification-center ul li i.close_btn {
  color: #fff !important;
  cursor: pointer; }

.notification-center ul li {
  cursor: pointer; }

.tasks-menu li {
  width: 100%; }

.marquee-text {
  display: inline-block;
  margin-top: 8px;
  max-width: 350px; }

.marquee-text marquee {
  font-weight: 700;
  font-size: 17px;
  color: #555; }

.addNewLead {
  height: 55px;
  width: 55px;
  background-color: #222d32;
  border-radius: 50%;
  display: inline-block;
  font-size: 30px;
  color: #fff;
  bottom: 50px;
  right: 50px;
  position: fixed;
  z-index: 1;
  padding: 8px;
  text-align: center; }

.sidebar-collapse .nav-division {
  display: none; }






.fa-tasks {
  background-color: #5cb85c !important; }

.tasks-pending {
  background-color: #d9534f !important; }

.tasks-meetin {
  background-color: #337ab7 !important; }

.timeline-body {
  white-space: pre-line; }

.timeline-body p {
  white-space: normal; }

.timeline .fa-sticky-note {
  background-color: #8a6d3b !important; }

.timeline .fa-envelope {
  background-color: #eea236 !important; }

.modal {
  overflow-y: auto; }

.divinactive {
  color: red;
  text-decoration: line-through; }

.divactive {
  color: green; }


.RegisterForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px; }

.RegisterForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0; }

.register-field {
  max-width: 283px;
  width: 100%;
  float: left;
  margin-right: 15px; }

.footer-main {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 19px 0;
  margin-top: -58px;
  position: relative;
  z-index: 1; }

.footer-main p {
  margin: 0px; }


.ResetPassword-input {
  margin-bottom: 0; }


















.verification-success {
  margin-top: 190px; }

a.btn.back-login {
  display: inline-block;
  background: #00A9FF;
  color: #fff;
  padding: 12px 18px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase; }



.direct-chat-messages {
  height: 500px !important; }

.contacts-list-name {
  font-weight: 600;
  line-height: 41px; }

.contacts-list-info, .contacts-list-date {
  color: #000 !important; }

.direct-chat-contacts {
  background: #fff !important;
  height: 75vh !important; }

[data-toggle="collapse"].collapsed .if-not-collapsed {
  display: none; }

[data-toggle="collapse"]:not(.collapsed) .if-collapsed {
  display: none; }

.contacts-list-selected, .contacts-list li:hover {
  background: #ccc; }

.user-bar .name {
  width: 400px !important; }

.sticker img {
  height: 190px;
  width: 190px; }

.sticker {
  background: none !important; }

.message:after {
  content: unset !important; }

.whatsapp_label {
  font-size: 20px;
  font-weight: bold;
  margin-top: 25px; }

/* Chat */
.chat {
  height: calc(100% - 69px); }

.chat-container {
  height: 100%; }

/* User Bar */
.user-bar {
  height: 55px;
  background: #005e54;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1; }

.user-bar:after {
  content: "";
  display: table;
  clear: both; }

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%; }

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px; }

.user-bar .actions.more {
  margin: 0 12px 0 32px; }

.user-bar .actions.attachment {
  margin: 0 0 0 30px; }

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg); }

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px; }

.user-bar .avatar img {
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  width: 100%; }

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 110px; }

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0; }

/* Conversation */
.conversation {
  position: relative;
  z-index: 0; }

.message strong {
  color: rgba(0, 0, 0, 0.76);
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  padding: 6px 7px 0 0px; }

.message p {
  color: #262626;
  font-size: 13.6px;
  /*line-height: 19px;
    padding: 7px 7px 6px 9px;*/
  white-space: pre-line;
  margin-bottom: 5px; }

.message .footer_text {
  color: #8696a0 !important;
  font-size: 13.6px;
  /*line-height: 19px;
    padding: 7px 7px 6px 9px;*/
  white-space: pre-line;
  margin-bottom: 5px; }

.message img {
  border-radius: 0px 5px 5px 5px; }

.message .metadata {
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  line-height: 17px;
  padding: 0 7px 8px 9px; }

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10; }

.conversation ::-webkit-scrollbar-track {
  background: transparent; }

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7; }

.conversation .conversation-container {
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px; }

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both; }

/* Messages */
.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word; }

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid; }

.metadata {
  display: inline-block;
  float: right;
  position: relative;
  bottom: -4px; }

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block; }

.metadata .tick {
  display: inline-block;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px; }

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out; }

.metadata .tick svg:first-child {
  backface-visibility: hidden;
  transform: perspective(800px) rotateY(180deg); }

.metadata .tick svg:last-child {
  backface-visibility: hidden;
  transform: perspective(800px) rotateY(0deg); }

.metadata .tick-animation svg:first-child {
  transform: perspective(800px) rotateY(0); }

.metadata .tick-animation svg:last-child {
  transform: perspective(800px) rotateY(-179.9deg); }

.message:first-child {
  margin: 16px 0 8px; }

.message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left; }

.message.received .metadata {
  padding: 0 0 0 16px; }

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px; }

.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right; }

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px; }

/* Compose */
.conversation-compose {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: end;
  align-items: flex-end;
  overflow: hidden;
  height: 50px;
  width: 100%;
  z-index: 2; }

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%; }

.conversation-compose .emoji {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 48px; }

.conversation-compose .input-msg {
  border: 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 50px; }

.conversation-compose .photo {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
  width: 48px; }

.conversation-compose .photo:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 0;
  right: -10px; }

.conversation-compose .photo i {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%; }

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none; }

.conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

.conversation-compose .send .circle i {
  font-size: 24px;
  margin-left: 5px; }

/* Small Screens */
@media (max-width: 768px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    -ms-flex: none;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%; }
  .marvel-device > .screen .chat {
    visibility: visible; }
  .marvel-device {
    visibility: hidden; }
  .marvel-device .status-bar {
    display: none; }
  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; } }


#googleMap {
  height: 500px;
  box-shadow: 3px 3px 10px gray; }


/*# sourceMappingURL=app.css.map */
